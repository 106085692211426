import React from 'react';
import './Footer.css';


const Footer = () => {
  return (
    <div className="container">
      <img className="logo" src='logo.png' alt='logo' /> <br />
      <hr />

      <div className="component">
        <img src='phone.png' alt='phone' />
        <p>+56 2 2696 6782</p>
      </div>

      <div className="component">
        <img src='mail.png' alt='mail' />
        <p>contacto@janaabogados.cl</p>
      </div>

      <div className="component">
        <img src='location.png' alt='location' />
        <p>Av. La Dehesa 440, Of. 622, Lo Barnechea</p>
      </div>

    </div>
  );
}

export default Footer;