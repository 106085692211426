import React from 'react';
import './App.css';
import Home from './Home.js'
import Nosotros from './Nosotros.js'
import AreasDePractica from './AreasDePractica.js'
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/nosotros" component={Nosotros} />
          <Route exact path="/areas-de-practica" component={AreasDePractica} />
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
