import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './Home.css';


const Home = () => {
  return (
    <div className="wrapper">
      <Navbar current="home" />
      <div className="banner">Estimados clientes, colegas y amigos, desde el 26 de junio nos encontramos en nuestra nueva oficina ubicada en Av. La Dehesa 440, oficina 622, Lo Barnechea</div>
      {/* <img className="cover1" src='portada.png' alt='city' /> */}
      <div className="typeh">
        <h1 className="title">Derecho de Familia y Sucesorio</h1>
        <p>Realizamos un trabajo personalizado para cada cliente.
        Nuestro estudio se ha ganado la reputación de ser los abogados de
        referencia para asuntos complejos de Derecho de Familia y Sucesorio de alto perfil.
        Manejamos todos los aspectos de casos complejos de disolución de matrimonio y/o
        uniones civiles, disolución y liquidación de los distintos regímenes patrimoniales del matrimonio,
        incluidas empresas de propiedad de los cónyuges y/o la pareja, pensión de alimentos, custodia y acceso a los
        hijos del padre no custodio, liquidaciones de comunidades hereditarias, particiones y general todas
      las materias relativas a nuestra especialidad.</p>
        <a className="contact-button" href="#contacto"><p>Contáctanos Aquí</p></a>
      </div>
      <section id="contacto">
        <h2 className="contact-title">Contacto</h2>
        <div className="contact-form">
          <form action="https://formspree.io/f/meqpzjog" method="POST">
            <div className="label-input">
              <label className="insert">Nombre</label>
              <div className="text-putter">
                <input type="text" name="Nombre"></input>
              </div>
            </div>
            <div className="label-input">
              <label className="insert">Apellido</label>
              <div className="text-putter">
                <input type="text" name="Apellido"></input>
              </div>
            </div>
            <div className="label-input">
              <label className="insert">Email</label>
              <div className="text-putter">
                <input type="text" name="Email"></input>
              </div>
            </div>
            <div className="label-input">
              <label className="insert">Telefono</label>
              <div className="text-putter">
                <input type="text" name="Telefono"></input>
              </div>
            </div>
            <div className="label-input">
              <label className="insert">Mensaje</label>
              <div className="text-putter">
                <textarea style={{ height: "130px" }} type="text" name="Mensaje"></textarea>
              </div>
            </div>
            <input className="contact-button-send" type="submit" value="Enviar" />
          </form>
        </div>
      </section>

      <Footer />

    </div>
  );
}

export default Home;