import React from 'react';
import './Team.css';


const Team = () => {
  return (
    <div className="team-container">
      <h1>Equipo</h1>
      <div className="main-image">
        <img className="rodrigo" src="rodrigo.jpeg" alt="rodrigojana" />
        <div className="main-image-text">
          <h3>Rodrigo Jana Linetzky</h3>
          <h6>Abogado titulado de la Universidad de Chile año 1994</h6>
          <h6>Miembro del Colegio de Abogados de Chile A.G.</h6>
        </div>
      </div>
      <div className="abogadas">
        <div className="abogada1">
          <img className="macarena" src="macarena.jpg" alt="macarena" />
          <div className="abogada1-text">
            <h3>Macarena Hermosilla</h3>
            <h6>Abogada titulada de la Universidad de Los Andes.</h6>
            <h6>Cuenta con 8 años de experiencia profesional.</h6>
            <h6>Ingresó a Jana Abogados el año 2015. Su práctica se enfoca en todas las áreas del Derecho de Familia negociaciones y litigios
            Es miembro de la Asociación de Abogados de Familia de Chile y del Colegio de Abogados.</h6>
          </div>
        </div>

        {/* <div className="abogada2">
          <img className="alexandra" src="alexandra.jpeg" alt="alexandra" />
          <div className="abogada2-text">
            <h3>Alexandra Nieto</h3>
            <h6>Abogada titulada de la Universidad de Chile, Magíster (c) con mención en Derecho Privado de la Universidad de Chile.</h6>
              <h6>Cuenta con 10 años de experiencia profesional.</h6>
              <h6>Ingresó a Jana Abogados el año 2011. Su práctica se enfoca en todas las áreas del Derecho de Familia negociaciones y litigios
              Es miembro de la Asociación de Abogados de Familia de Chile.</h6>
          </div>
        </div> */}
        {/* <div className="abogada3">
          <img className="ximena" src="ximena.jpeg" alt="ximena" />
          <div className="abogada3-text">
            <h3>Ximena Barros</h3>
            <h6>Abogada titulada de la Universidad Central, diplomado en Derecho Laboral y Empresas de la Universidad de Chile.</h6>
            <h6>Cuenta con 5 años de experiencia profesional.</h6>
            <h6>Ingresó a Jana Abogados el año 2018. Su práctica se enfoca en todas las áreas del Derecho de Familia y Laboral negociaciones y litigios.</h6>
          </div>
        </div> */}
        <div className="abogada2">
          <img className="" src="gabriela.jpg" alt="gabriela" />
          <div className="abogada2-text">
            <h3>Gabriela Wacquez</h3>
            <h6>Abogada titulada de la Universidad de los Andes.</h6>
            <h6>Cuenta con 6 años de experiencia profesional.</h6>
            <h6>Ingresó a Jana Abogados el año 2022. Su práctica se enfoca en todas las áreas del Derecho de Familia, negociaciones y litigios. Es miembro del Colegio de Abogados.</h6>
          </div>
        </div>
        <div className="abogada3">
          <img className="anita" src="anita.jpeg" alt="anita" />
          <div className="abogada3-text">
            <h3>Anita María Araya</h3>
            <h6>Abogada titulada de la Universidad Adolfo Ibañez.</h6>
            <h6>Recibió su título de Abogada otorgado por la Corte Suprema el día 22 de diciembre del año 2022.</h6>
            <h6>Ingresó a Jana Abogados el año 2023. Su práctica se enfoca en todas las áreas del Derecho de Familia, negociaciones y litigios.</h6>
          </div>
        </div>
      </div>

      {/* <p style={{ fontSize: '14px' }}>Informamos que Ximena ha emprendido nuevos desafíos profesionales y le deseamos mucho éxito en sus nuevos desafíos.</p> */}
    </div>
  );
}

export default Team; 