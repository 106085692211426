import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './AreasDePractica.css';


const AreasDePractica = () => {
  const contactUs = () => {
    window.location.href = '/#contacto'
  }

  return (
    <div>
      <Navbar current="areas-de-practica" />
      <img className="cover1" src='areas.jpg' alt='areas' />
      <div className="type">
        <h1 className="title">Áreas de Práctica</h1>
        <p className="text">A continuación presentamos los principales servicios que ofrecemos
        a nuestros clientes. Además, hemos formado alianzas estratégicas con otros estudios de
        abogados brindando una asistencia integral.</p>
      </div>
      <div className="container-legal">
        <div className="box">
          <div className="text-box">
            <h1>Derecho de Familia</h1>
            <hr />
            <ol>
              <li>Negociación en solución de controversias de áreas de práctica</li>
              <li>Divorcios (por culpa, cese de convivencia y  mutuo acuerdo)</li>
              <li>Compensación económica</li>
              <li>Liquidación de sociedad conyugal y participación en gananciales</li>
              <li>Disolución y liquidación de sociedades comerciales familiares</li>
              <li>Bienes familiares</li>
              <li>Pensión alimenticia</li>
              <li>Custodia del (los) hijo (os)</li>
              <li>Relación directa y regular</li>
              <li>Protección de menores</li>
              <li>Violencia intrafamiliar</li>
              <li>Aspectos civiles del secuestro internacional de menores</li>
              <li>Filiación</li>
              <li>Interdicciones</li>
            </ol>
          </div>

        </div>

        <div className="box">
          <div className="text-box">
            <h1>Derecho Sucesorio</h1>
            <hr />
            <ol>
              <li>Testamentos</li>
              <li>Posesiones efectivas</li>
              <li>Liquidaciones de comunidades hereditarias</li>
              <li>Particiones</li>
            </ol>
          </div>
        </div>
      </div>



      <Footer />

    </div>
  );
}

export default AreasDePractica;