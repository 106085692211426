import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({ current }) => {
  return (
    <div>
      <img className="logo" src='logo.png' alt='city' />
      <div className='buttons'>
        {current === 'home' ? <Link to="/" className="button selected">INICIO</Link> : <Link to="/" className="button">INICIO</Link>}
        {current === 'nosotros' ? <Link to="/nosotros" className="button selected">NOSOTROS</Link> : <Link to="/nosotros" className="button">NOSOTROS</Link>}
        {current === 'areas-de-practica' ? <Link to="/areas-de-practica" className="button selected">ÁREAS DE PRÁCTICA</Link> : <Link to="/areas-de-practica" className="button">ÁREAS DE PRÁCTICA</Link>}
        <a href="/#contacto" className="button">CONTACTO</a>
      </div>
    </div>
  );
}

export default Navbar; 