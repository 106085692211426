import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './Nosotros.css';

import Team from './Team';

const Nosotros = () => {
  const areasDePractica = () => {
    window.location.href = '/areas-de-practica'
  }

  return (
    <div>
      <Navbar current="nosotros" />
      <img className="cover1" src='history.jpg' alt='library' />
      <div className="type">
        <h1 className="title">Nuestra Historia</h1>
        <p className="text">Estudio Jurídico fundado en 1962 por Nissim Jana Toledo,
        ha mantenido desde su formación como principal objetivo el ofrecer un servicio profesional
        especializado e integral a sus clientes en casos de alta complejidad, donde el profesionalismo,
        lealtad, compromiso y excelencia lo han caracterizado en sus 58 años de vida. Hoy el estudio es
        liderado por Rodrigo Jana Linetzky, hijo de su fundador, quien ha especializado el servicio
        profesional en áreas de negociación y litigación de alta complejidad en el
        Derecho de Familia y Sucesorio y de manera integral en otras áreas de práctica
        en alianza con destacados abogados y estudios jurídicos.</p>
        <a className="contact-button" href="/areas-de-practica"><p>Áreas de Práctica</p></a>
      </div>
      <Team />
      <Footer />

    </div>
  );
}

export default Nosotros;